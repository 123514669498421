<template>
  <div class="support-tab shared-index">
    <el-row
      class="table-title"
      type="flex"
      align="center"
      justify="space-between"
    >
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane name="image">
            <div slot="label">
              Hỉnh ảnh
              <span v-if="activeTab === 'image'">({{ total }})</span>
            </div>
          </el-tab-pane>
          <el-tab-pane name="video">
            <div slot="label">
              Video
              <span v-if="activeTab === 'video'">({{ total }})</span>
            </div>
          </el-tab-pane>
          <el-tab-pane name="audio">
            <div slot="label">
              Audio
              <span v-if="activeTab === 'audio'">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div style="display: flex; align-items: center;">
          <el-upload
            v-if="activeTab !== 'video'"
            class="avatar-uploader mr-2"
            :accept="`${activeTab}/*`"
            :list-type="activeTab === 'image' ? 'picture' : 'file'"
            multiple
            action
            :show-file-list="false"
            :http-request="uploadFiles"
          >
            <el-button
              type="primary"
              size="small"
              title="Chọn từ máy tính của bạn"
              :loading="uploading"
            >Upload</el-button>
          </el-upload>
          <el-button
            v-else
            class="mr-2"
            type="primary"
            size="small"
            title="Thêm video từ máy tính"
            @click="updateMedia(null)"
          >Thêm</el-button>
          <import-file-urls-button
            class="mr-2"
            size="small"
            :disabled="loading"
            :total="total"
            :columns="fileUrlsColumns"
            :type="'media'"
            @reload="handleChangeTab"
          />
          <import-excel-button
            class="mr-2"
            size="small"
            order="-created_at"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="activeTab"
            :titles="importExportTitles"
            :active-tab="activeTab"
            @reload="handleChangeTab"
          />
          <export-excel-button
            class="mr-2"
            size="small"
            order="-created_at"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="activeTab"
            :titles="importExportTitles"
            :active-tab="activeTab"
          />
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            size="small"
            @click="handleChangeTab"
          >Làm mới</el-button>
        </div>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên"
          size="small"
          @keyup.enter.native="handleSearchText"
        ></el-input>
      </div>
    </el-row>
    <el-row type="flex" class="my-2 px-2">
      <el-select
        v-model="placeType"
        filterable
        class="mr-2"
        placeholder="Chọn loại địa danh"
      >
        <el-option
          v-for="t in placeTypes"
          :key="t.value"
          :label="t.name"
          :value="t.value"
        ></el-option>
      </el-select>
      <el-select
        v-model="placeId"
        filterable
        remote
        placeholder="Chọn điểm đến"
        :remote-method="searchPlace"
        :loading="searching"
        :disabled="!placeType"
        style="width: 500px;"
        @change="handleChangePlace"
      >
        <el-option
          v-for="p in places"
          :key="p.id"
          :label="p.name"
          :value="p.id"
        ></el-option>
      </el-select>
    </el-row>
    <list-media
      :loading="loading"
      :data="medias"
      :type="activeTab"
      @update="updateMedia"
      @show="showMedia"
      @delete="deleteMedias"
    />
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <show-images-popup
      v-if="activeTab === 'image' && visible"
      :visible="visible"
      :selected-img="selectedMedia"
      :images="[selectedMedia]"
      @close="() => { visible = false }"
      @reload="loadData"
    />
    <show-video
      v-if="activeTab === 'video' && showVisible"
      :visible="showVisible"
      :video="selectedMedia"
      @close="() => { showVisible = false }"
    />
    <upload-video-popup
      v-if="activeTab === 'video' && visible"
      :visible="visible"
      :video="selectedMedia"
      @reload="loadData"
      @close="() => visible = false"
    />
    <update-audio-popup
      v-if="activeTab === 'audio' && visible"
      :visible="visible"
      :audio="selectedMedia"
      @reload="loadData"
      @close="() => visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListMedia from './components/List.vue'
import ShowImagesPopup from '../../components/popups/ShowImages.vue'
import ShowVideo from '../../components/popups/ShowVideo.vue'
import UploadVideoPopup from '../../components/popups/UploadVideo'
import UpdateAudioPopup from '../../components/popups/UpdateAudio.vue'
import ImportExcelButton from '../../components/buttons/ImportExcel.vue'
import ExportExcelButton from '../../components/buttons/ExportExcel.vue'
import ImportFileUrlsButton from '../../components/buttons/ImportFileUrls.vue'

import { listPlaces } from '@/services/place'
import { listStorages, deleteStorages, getStorage, createStorage } from '@/services/storage'
import { getTranslation, getPlaceTypes } from '@/utils/filters'
import TYPE from '@/data/type'

export default {
  name: 'Media',
  components: {
    ListMedia,
    ShowImagesPopup,
    ShowVideo,
    UploadVideoPopup,
    UpdateAudioPopup,
    ImportExcelButton,
    ExportExcelButton,
    ImportFileUrlsButton
  },
  data() {
    return {
      visibleUpdate: false,
      loading: false,
      medias: [],
      selectedDepartment: {},
      activeTab: 'image',
      total: 0,
      limit: 50,
      page: 1,
      searchText: '',
      visible: false,
      showVisible: false,
      selectedMedia: null,
      uploading: false,
      placeType: null,
      placeId: null,
      searching: false,
      places: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeTypes() {
      return getPlaceTypes(false).filter((p) => p.type === 'place')
    },
    excelColumns() {
      let eColumns = ['id', 'name', 'author']
      if (this.activeTab === 'video') {
        eColumns = ['id', 'title', 'description']
      }
      return (eColumns || []).map((c) =>
        TYPE.EXCEL_COLUMNS.find((column) => c === column.key)
      )
    },
    fileUrlsColumns() {
      const eColumns = ['url', 'path', 'object_id', 'object_type', 'file_type', 'description', 'title', 'is_avatar', 'size']
      return (eColumns || []).map((c) =>
        TYPE.EXCEL_COLUMNS.find((column) => c === column.key)
      )
    },
    params() {
      const p = {
        page: this.page,
        per_page: this.limit,
        locale: this.language,
        file_type: this.activeTab,
        searchText: this.searchText,
        order: '-created_at',
        object_type: 'place',
        object_id: this.placeId
      }
      return p
    },
    importExportTitles() {
      return [
        { name: 'Hình ảnh', value: 'image' },
        { name: 'Âm thanh', value: 'audio' },
        { name: 'Video', value: 'video' }
      ]
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.visibleUpdate = false
      listStorages(this.params).then(async (response) => {
        this.total = response.total
        if (this.activeTab === 'video') {
          this.medias = response.result.map((v) => ({
            ...v,
            title: getTranslation(v, this.language)
              ? getTranslation(v, this.language).title
              : '',
            description: getTranslation(v, this.language)
              ? getTranslation(v, this.language).description
              : ''
          }))
          const requests = []
          this.medias.forEach(v => {
            if (v.avatar_id) {
              requests.push(getStorage({ id: v.avatar_id, locale: this.language }).then((res) => res).catch(() => {}))
            }
          })
          await Promise.all(requests).then((images) => {
            this.medias = this.medias.map((v) => {
              const avatar = images.find((a) => a.id === v.avatar_id)
              return {
                ...v,
                avatar: avatar || null
              }
            })
          })
        } else {
          this.medias = response.result
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    updateMedia(media) {
      this.selectedMedia = media
      this.visible = true
    },
    showMedia(media) {
      this.selectedMedia = media
      this.showVisible = true
    },
    deleteMedias(imgs) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        const ids = imgs.map((i) => i.id)
        deleteStorages({
          file_type: this.activeTab,
          ids: ids.join(',')
        })
          .then(() => {
            if (this.medias.length === imgs.length && this.page > 1) {
              this.page = this.page - 1
            }
            this.loadData()
          })
          .catch(() => {
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },
    uploadFiles(fileInfo) {
      this.uploading = true
      const params = {
        file: fileInfo.file,
        objectId: '',
        objectType: 'place',
        fileType: this.activeTab,
        locale: this.language
      }
      createStorage(params)
        .then(() => {
          this.loadData()
          this.uploading = false
        })
        .catch(() => {
          this.uploading = false
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể upload file: ' + fileInfo.file.name
          })
        })
    },
    handleSearchText() {
      this.page = 1
      this.loadData()
    },
    handleChangePlace() {
      this.page = 1
      this.loadData()
    },
    getPlaces() {
      this.searching = true
      const params = {
        page: 1,
        per_page: 10,
        locale: this.language,
        place_types: this.placeType
      }
      const request = listPlaces(params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.support-tab {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
